<template>
    <div class="modal fade" tabindex="-1" id="indicatorsModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('modal.title') }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <el-scrollbar height="600px">
          <p class="modal-sub">{{ $t('modal.sub') }}</p>
          <table class="table">
            <thead>
              <tr>
                <th>{{ $t('modal.head1') }}</th>
                <th>{{ $t('modal.head2') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $t('modal.indicator1') }}</td>
                <td>kg CO2 eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator2') }}</td>
                <td>disease incidence</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator3') }}</td>
                <td>m3 world eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator4') }}</td>
                <td>MJ</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator5') }}</td>
                <td>
                  point<br />
                  kg biotic production<br />
                  kg soil<br />
                  m3 water<br />
                  m3 groundwater
                </td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator6') }}</td>
                <td>kg Sb eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator7') }}</td>
                <td>kg CFC-11 eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator8') }}</td>
                <td>mol H+ eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator9') }}</td>
                <td>kBq U235 eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator10') }}</td>
                <td>kg NMVOC eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator11') }}</td>
                <td>mol N eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator12') }}</td>
                <td>kg N eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator13') }}</td>
                <td>kg P eq</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator14') }}</td>
                <td>CTUe</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator15') }}</td>
                <td>CTUh</td>
              </tr>
              <tr>
                <td>{{ $t('modal.indicator16') }}</td>
                <td>CTUh</td>
              </tr>
            </tbody>
          </table>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'InfoModal'
}
</script>

<style lang="scss">
.modal-dialog {
  max-width: 70%;
  .modal-sub {
    font-size: 14px;
  }
  .table {
    max-width: 90%;
    margin: 0 auto;
    thead {
      font-size: 16px;
    }
    td {
      font-size: 14px;
    }
  }
}

@include bp-down(md) {
  .modal-dialog {
    max-width: 95%;
  }
}
</style>