<template>
  <el-collapse v-model="activeItem">
    <el-collapse-item
      v-for="indicator in indicators"
      :key="indicator.name"
      :name="indicator.name"
    >
      <template #title>
        <p class="collapse-item-title">
          {{ $t(`indicators.${indicator.name}.title`) }}
        </p>
        <AppRating :value="indicator.score" />
      </template>
      <p class="collapse-description mt-2">
        {{ $t(`indicators.${indicator.name}.text1`) }}
        <span
          style="cursor: pointer"
          data-bs-toggle="modal"
          data-bs-target="#indicatorsModal"
        >
          <AppTooltip
            v-if="indicator.name === 'single'"
            :info="$t(`indicators.${indicator.name}.link`)"
          />
        </span>
      </p>

      <p class="collapse-description mt-2">
        {{ $t(`indicators.${indicator.name}.text2`) }}
      </p>
      <p class="collapse-description mt-2" v-if="indicator.name === 'single'">
        {{ $t(`indicators.${indicator.name}.text3`) }}
      </p>
      <p class="collapse-weight mt-2">{{ $t('indicators.impact') }}:</p>
      <p class="mt-2">
        <AppIndicator :indicator="indicator.score" :stroke="8" />
        {{ indicator.impact }} {{ $t(`indicators.${indicator.name}.unit`) }}
      </p>
      <p class="collapse-weight mt-2 mb-0">
        {{ $t(`indicators.${indicator.name}.weight`) }}
      </p>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: 'Indicators',
  props: ['indicators'],
  data() {
    return {
      activeItem: ''
    }
  }
};
</script>

<style lang="scss">
.collapse-item-title {
  font-size: 18px;
  padding-top: 1rem;
  &:hover {
    color: $primary;
  }
}
.collapse-description,
.collapse-weight {
  font-size: 16px;
}
.collapse-weight {
  font-weight: 600;
}

@include bp-down(md) {
  .collapse-item-title {
    font-size: 14px;
        line-height: 1!important;
  }
  .collapse-description,
  .collapse-weight {
    font-size: 12px;
  }
}
</style>
