const enginesEn = [
  {
    id: 'diesel',
    name: 'diesel',
    title: 'Diesel',
  },
  {
    id: 'petrol',
    name: 'petrol',
    title: 'Gasoline',
  },
  {
    id: 'cng',
    name: 'cng',
    title: 'Compressed natural gas',
  },
  {
    id: 'hev',
    name: 'hev',
    title: 'Hybrid',
  },
  {
    id: 'phev-low',
    name: 'phev',
    title: 'Plug-in hybrid',
    sub: 'Low electricity consumption',
  },
  {
    id: 'phev-medium',
    name: 'phev',
    title: 'Plug-in hybrid',
    sub: 'Medium electricity consumption',
  },
  {
    id: 'phev-high',
    name: 'phev',
    title: 'Plug-in hybrid',
    sub: 'High electricity consumption',
  },
  {
    id: 'bev-small',
    name: 'bev',
    title: '100% electric',
    sub: 'Small battery',
  },
  {
    id: 'bev-medium',
    name: 'bev',
    title: '100% electric',
    sub: 'Medium battery',
  },
  {
    id: 'bev-large',
    name: 'bev',
    title: '100% electric',
    sub: 'Large battery',
  },
  {
    id: 'fcev',
    name: 'fcev',
    title: 'Hydrogen',
  },
];

const enginesFr = [
  {
    id: 'diesel',
    name: 'diesel',
    title: 'Diesel',
  },
  {
    id: 'petrol',
    name: 'petrol',
    title: 'Essence',
  },
  {
    id: 'cng',
    name: 'cng',
    title: 'Gaz naturel comprimé',
  },
  {
    id: 'hev',
    name: 'hev',
    title: 'Hybride',
  },
  {
    id: 'phev-low',
    name: 'phev',
    title: 'Hybride rechargeable',
    sub: "Basse consommation d'électricité",
    description:
      "Un véhicule hybride rechargeable est un véhicule hybride électrique dont la batterie de traction est conçue pour être chargée par branchement à une source d'énergie extérieure.",
  },
  {
    id: 'phev-medium',
    name: 'phev',
    title: 'Hybride rechargeable',
    sub: "Consommation d'électricité moyenne",
    description:
      "Un véhicule hybride rechargeable est un véhicule hybride électrique dont la batterie de traction est conçue pour être chargée par branchement à une source d'énergie extérieure.",
  },
  {
    id: 'phev-high',
    name: 'phev',
    title: 'Hybride rechargeable',
    sub: "Haute consommation d'électricité",
    description:
      "Un véhicule hybride rechargeable est un véhicule hybride électrique dont la batterie de traction est conçue pour être chargée par branchement à une source d'énergie extérieure.",
  },
  {
    id: 'bev-small',
    name: 'bev',
    title: 'Electrique',
    sub: 'Petite batterie',
  },
  {
    id: 'bev-medium',
    name: 'bev',
    title: 'Electrique',
    sub: 'Batterie moyenne',
  },
  {
    id: 'bev-large',
    name: 'bev',
    title: 'Electrique',
    sub: 'Grande batterie',
  },
  {
    id: 'fcev',
    name: 'fcev',
    title: 'Hydrogène',
  },
];

export { enginesEn, enginesFr };
